import React, {useEffect} from 'react'
import classes from '../styles/video.module.sass'

// Links to video files use <video>, otherwise we assume link to a website (e.g. YouTube) and use <iframe>.
// If the given URL ends with any of the following file extensions, it uses <video>, otherwise <iframe>.
const videoFileExtensions = ['mp4', 'webm'];  // could be extended
const videoFileRegex = new RegExp("(?:\\." + videoFileExtensions.join("|") + ")$");

const Video = ( {toggleButtonComplete, url, updateData, didMount} ) => { 

    /*useEffect(() => {
        updateData([])
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete])*/
    

    useEffect(() => {
        if (didMount) {
            updateData([])
        }
        
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete]) 

    let videoUrl = url.match(videoFileRegex);

    return (
            <div className={classes.videoContainer}>
            {videoUrl && (
                <video controls preload="auto" >
                    <source src={url} />
                </video>
            )}
            {!videoUrl && (
                <iframe title="Video" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen src={url}>
                </iframe>
            )}
        </div>
    );
};

export default Video 
