import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import classes from '../../styles/multi-choice.module.sass'

const AnswerInfo = ({isCorrect, isPartial = () => false, right = null, wrong = null, partial = null, children = []}) => { 

    const data = useStaticQuery(graphql`
      query AnswerInfoQuery {
        site {
          siteMetadata {
            i18n {
              answers {
                correct
                partiallyCorrect
                incorrect
              }
            }
          }
        }
      }
    `);
    const { i18n } = data.site.siteMetadata;

    const infoTheme = () => {
        if(isCorrect()) {
            return classes.correct
        } else if(isPartial()) {
            return classes.partial
        } else {
            return classes.wrong
        }
    }

    const defaultText = () => {
        return isCorrect() ? i18n.answers.correct : isPartial() ? i18n.answers.partiallyCorrect : i18n.answers.incorrect
    }

    const userText = () => {
        return isCorrect() ? right : isPartial() ? partial : wrong
    }

    return (
        <div
                        
        className={classNames(classes.answer, { [infoTheme()]: true })}
        >
        <strong
            className={classNames(classes.answerLabel, {
                [classes.answerLabelCorrect]: isCorrect(),
            })}
        >
            {defaultText()}
        </strong><br></br>
        {
            userText()
        }
        {children}
         </div>
    )
}

export default AnswerInfo
