import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

import IconCheck from '../../static/icon_check.svg'
import classes from '../styles/button.module.sass'
import { TaskContext } from "../context/index";

export const Button = ({ Component = 'button', children, onClick, variant, small, className }) => {
    const taskContext = useContext(TaskContext);
    
    const buttonClassNames = classNames(classes.root, className, {
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
        [classes.small]: !!small,
    })
    return (
        <Component disabled={taskContext.taskCompleted ? 'disabled' : null}  className={buttonClassNames} onClick={onClick}>
            {children}
        </Component>
    )
}

export const CompleteButton = ({ skipComplete, completed, toggleComplete, toggleButtonComplete, updateData, small = true }) => {
    const buttonClassNames = classNames({
        [classes.completeInactive]: !completed,
        [classes.completeActive]: completed,
    })

    const [didMount, setDidMount] = useState(false)

    useEffect(() => {
        setTimeout(() => setDidMount(true), 100)
    }, [])

    useEffect(() => {
        if(didMount && !skipComplete)
            updateData(null, completed)
    }, [toggleButtonComplete])

    const data = useStaticQuery(graphql`
      query CompleteButtonQuery {
        site {
          siteMetadata {
            i18n {
              buttons {
                complete
                completeMark
                completeUnmark
              }
            }
          }
        }
      }
    `);
    const { i18n } = data.site.siteMetadata;

    return (
        <>
        {skipComplete ? 
        <>
		{/* 
		
		Commenting out this chunk of code completely removes the completeButton if skipComplete is specified. //FL
        

		<Button small={small} className={buttonClassNames}>
            {!completed ? (
                'Slutför uppgifterna för godkänt'
            ) : (
                <>
                    <span className={classes.completeLabel}>Klart</span>{' '}
                    <span className={classes.completeLabelHover}>Uppgiften är slutförd</span>
                </>
            )}
            </Button>
		*/}
        </> : 
            <Button small={small} onClick={toggleComplete} className={buttonClassNames}>
                {!completed ? (
                    i18n.buttons.completeMark
                ) : (
                    <>
                        <IconCheck width={14} height={14} className={classes.completeIcon} />{' '}
                        <span className={classes.completeLabel}>{ i18n.buttons.complete }</span>{' '}
                        <span className={classes.completeLabelHover}>{ i18n.buttons.completeUnmark }</span>
                    </>
                )}
            </Button>
        }
        
        </>
    )
}
