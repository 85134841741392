import React from 'react';


const TaskContext = React.createContext({
    excerciseData: {},
    setExcerciseData: () => {}
  });


const MultianswerContext = React.createContext()

export { TaskContext, MultianswerContext }