import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Button } from '../button'
import { MathArea } from '../math'
import classes from '../../styles/choice.module.sass'
import AnswerInfo from './answer-info'
import {randomId, hasChildren, findChild} from '../../utility/index'
import { Input } from '../input'
import { useEffect } from 'react'
import { Option } from "./option"
import { useMsal } from '../../utility/auth/msalContext';
import { loginRequest } from "./../../utility/auth/msalConfig";



const Choice = ({ id = `${randomId()}`, right="", children = [], excerciseData, toggleButtonComplete, updateData, forceComplete, forceUncomplete, setExcerciseData, didMount}) => {
    const [selected, setSelected] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [options, setOptions] = useState([])
    const { loading, isAuthenticated, login, logout, token } = useMsal();

    const data = useStaticQuery(graphql`
      query ChoiceQuery {
        site {
          siteMetadata {
            i18n {
              answers {
                loginToAnswer
                submit
              }
            }
          }
        }
      }
    `);
    const { i18n } = data.site.siteMetadata;

    useEffect(() => {
        const optionsMARKDOWN = children.filter(child => child !== "\n")
        const filtered = [...optionsMARKDOWN.map(a => (hasChildren(a) ? findChild(a, Option.tag) : null))]; //a.type.name == "Option" || a.type.displayName == "Option" ? a : (hasChildren(a) ? optionChild(a) : null))];
        setOptions([...filtered.filter(a => a != null)]);
    }, [children]);

    // No idea what happens. Fix this later.
    const isCorrect = () => {
        let isCorrect = false

        options.map(({ key, props }, i) => {
            if(answer === i && props.correct)
                isCorrect = true

        })

        return isCorrect
    }

    const handleAnswer = useCallback(() => {
        setAnswer(selected);
        setExcerciseData(selected)
        //updateData(selected)
    // Callback only on selected update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        if (didMount) {
            if (isCorrect()) {
                forceComplete()
                updateData(selected, true)
            } else {
                forceUncomplete()
                updateData(selected, false)
            }
        }
    // Callback only on answer update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer])

    useEffect(() => {
        setSelected(excerciseData)
    }, [excerciseData])

    /*useEffect(() => {
        updateData(selected)
         // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete])*/

    /* useEffect(() => {
        if (didMount) {
            //updateData(selected)
        }

         // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete]) */


    return (
        <MathArea>
            {options.map(({ key, props }, i) => (
                <p key={key} className={classes.option}>
                    <Input
                        className={classes.input}
                        name={`choice-${id}-${i}`}
                        id={`choice-${id}-${i}`}
                        value={i}
                        type="radio"
                        checked={selected === i}
                        onChange={() => setSelected(i)}
                        />
                    <label
                        className={classes.label}
                        htmlFor={`choice-${id}-${i}`}
                        dangerouslySetInnerHTML={{ __html: `${props.text}` }}
                    />
                </p>
            ))}

            {(!isAuthenticated || !token) && (
                    <Button variant="primary" onClick={() => login(loginRequest, "loginRedirect")}>{ i18n.answers.loginToAnswer }</Button>
            )}
            {isAuthenticated && token && (
                <Button variant="primary" onClick={handleAnswer}>
                    { i18n.answers.submit }
                </Button>
            )}
            
            {options.map(({ key, props }, i) => {
                const isCorrect = !!props.correct

                return answer === i ? (
                    <div key={key}>
                    {

                         <AnswerInfo isCorrect={() => isCorrect} right={props.answer} wrong={props.answer} children={props.feedback || ""}></AnswerInfo>
                    }
                    </div>
                ) : null
            })}
        </MathArea>
    )
}

export default Choice
