import React, { useRef, useCallback, useContext, useEffect } from 'react'
import classNames from 'classnames'
import classes from '../styles/infobox.module.sass'

const Infobox= ({ title, type="practical", children, kernelHandler}) => {
    const ALLOWED_TYPES = [
        "code",
        "theory",
        "practical", // Practical considerations
    ]

    if(!(ALLOWED_TYPES.includes(type))){
        type = "practical" // Default to this
    }

    const rootName = `${type}Root`
    const titleName = `${type}Title`

    const rootClassNames = classNames(classes[rootName], {})
    const titleClassNames = classNames(classes[titleName], {});

    const childrenCopy = React.Children.map(children, child =>
        React.cloneElement(child, {
            excerciseData: [],
            kernelHandler: kernelHandler
        }));

    return (
        <section className={rootClassNames}>
            <h2 className={titleClassNames} >
                {title}
            </h2>
            {(
                <div>
                    {childrenCopy}
                </div>
            )}
        </section>
    )
}

export default Infobox
