import React, { useState, useCallback, useContext } from 'react'

import { Button } from '../button'
import AnswerInfo from './answer-info'
import {randomId, hasChildren, findChild} from '../../utility/index'
import { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {Option} from "./option"
import classes from '../../styles/select-choice.module.sass'

import { useMsal } from '../../utility/auth/msalContext';
import { loginRequest } from "./../../utility/auth/msalConfig";

import { TaskContext } from "../../context/index";

const SelectChoice = ({ id = `${randomId()}`,right="", children = [] }) => {
    const [selected, setSelected] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [options, setOptions] = useState([])

    const { loading, isAuthenticated, login, logout, token } = useMsal();

    const data = useStaticQuery(graphql`
      query SelectChoiceQuery {
        site {
          siteMetadata {
            i18n {
              answers {
                loginToAnswer
                submit
              }
            }
          }
        }
      }
    `);
    const { i18n } = data.site.siteMetadata;

    useEffect(() => {
        const optionsMARKDOWN = children.filter(child => child !== "\n")
        const filtered = [...optionsMARKDOWN.map(a => (hasChildren(a) ? findChild(a, Option.tag) : null))];
        setOptions([...filtered]); 
    }, [children]);

    const taskContext  = useContext(TaskContext);
    const handleAnswer = useCallback(() => setAnswer(selected), [selected])

    return (
        <>
            <select className={classes.selectChoice} disabled={taskContext.taskCompleted ? 'disabled' : null} onBlur={e => setSelected(parseInt(e.target.value))}>
                <option key={-1}>{"--select answer--"}</option>
                {options.map(({ key, props }, i) => (         
                    <option key={key} value={i}>{props.text}</option>
                ))}
            </select>

            {(!isAuthenticated || !token) && (
                    <Button variant="primary" onClick={() => login(loginRequest, "loginRedirect")}>{ i18n.answers.loginToAnswer }</Button>
            )}

            {isAuthenticated && token && (
                <Button variant="primary" onClick={handleAnswer}>
                    { i18n.answers.submit }
                </Button>
            )}
         
            {options.map(({ key, props }, i) => {
                const isCorrect = !!props.correct

                return answer === i ? (  
                    <div key={i}>
                    {                     
                        <AnswerInfo isCorrect={() => isCorrect} children={props.feedback || ""}></AnswerInfo>
                    }                                   
                    </div>
                ) : null
            })}
        </>
    )
}

export default SelectChoice
