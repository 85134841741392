import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import { CompleteButton } from './button'
import IconSlides from '../../static/icon_slides.svg'
import classes from '../styles/exercise.module.sass'
import { useMsal } from '../utility/auth/msalContext';
import { TaskContext } from "../context/index"; 
import { useEffect } from 'react'

const TaskExercise = ({ id, title, skipcomplete, chapterData, kernelHandler, type, children }) => {
    const excRef = useRef()
    const [completed, setCompleted] = useState(false)
    const [toggleButtonComplete, setToggleButtonComplete] = useState(false)
    const [excerciseData, setExcerciseData] = useState([])

    // Initializing didMount as false
    const [didMount, setDidMount] = useState(false)

    // Setting didMount to true upon mounting
    useEffect(() => {
        
        setDidMount(true) 
    }, [])

    const { isAuthenticated, progress, saveCompletedExerciseToApI} = useMsal();

    const chapter_id = chapterData.chapter_id
    const taskId = chapterData.id

    const toggleComplete = () => {    
        setCompleted(!completed)
        setToggleButtonComplete(!toggleButtonComplete)
    }

    /*const updateData = async (data) => {   
        
        console.log("updateData")
        console.log("id", id)
        console.log("data", data)
        console.log("completed", completed)
        let output_data = []
        
        if (completed) {
            console.log("is completed")
            output_data = { 
                status: "complete",
                data: data
            }
        }
        else if(!completed) {
            console.log("is not completed")
            output_data = { 
                status: "uncomplete",
                data: data
            }
        }

        console.log("output_data", output_data) 
        await saveCompletedExerciseToApI(chapter_id, taskId, id, output_data); 
    }*/

    const updateData = async (data, completed_status) => {   
        
        let output_data = []
        
        if (completed_status) {
            output_data = { 
                status: "complete",
                data: data
            }
        }
        else if(!completed_status) {
            output_data = { 
                status: "uncomplete",
                data: data
            }
        }

        await saveCompletedExerciseToApI(chapter_id, taskId, id, output_data); 
    }

    const forceComplete = () => 
    {
        if (!completed) 
            toggleComplete()
    }

    const forceUncomplete = () => 
    {
        if (completed) 
            toggleComplete()
    }


      useEffect(() => {
            
            if(!progress) {
                console.debug("Failed to retrieve progress!", progress)
                return
            }

            const chapterFound = progress.modules.find(element =>  element.moduleId === chapter_id)
            if (chapterFound) {
               
                const taskFound = chapterFound.sections.find(element => element.sectionId === taskId)
                if (taskFound) { 
                    const exerciseFound = taskFound.exercises.find(element => element.exerciseId === id)
                    if(exerciseFound) {
                        const data = JSON.parse(exerciseFound.exerciseData)
                        setExcerciseData(data.data)

                        if (data.status === "complete"){                       
                            setCompleted(true)
                        }
                        else {
                            setCompleted(false)
                        }
                    }
                }
            } 
    // Callback only on progress update
    // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [progress]);
     
    
    const rootClassNames = classNames(classes.root, {
        [classes.expanded]: true,
        [classes.completed]: completed,
    })
    
    const titleClassNames = classNames(classes.title, {
        [classes.titleExpanded]: true,
    });
    
    const childrenCopy = React.Children.map(children, child =>
        React.cloneElement(child, { updateData: updateData,
            didMount: didMount,
            settoggleButtonComplete: setToggleButtonComplete, 
            excerciseData: excerciseData, 
            toggleButtonComplete: toggleButtonComplete, 
            setExcerciseData: setExcerciseData, 
            completed: completed, 
            forceComplete: forceComplete,
            forceUncomplete: forceUncomplete, 
            kernelHandler: kernelHandler 
        }));
    
    return (
    
        <section ref={excRef} id={id} className={rootClassNames}>
            <h2 className={titleClassNames}>

                <h3>
                    {title}
                </h3>
                {type === 'slides' && <IconSlides className={classes.icon} />}
            </h2>
   
            <div>

            <TaskContext.Provider value={{completed, excerciseData, setExcerciseData}}>       
                {childrenCopy}     

            </TaskContext.Provider>

                <footer className={classes.footer}>
                    {isAuthenticated && <CompleteButton
                        completed={completed}
                        toggleComplete={toggleComplete}
                        skipComplete ={skipcomplete !== undefined}
                    />
                }
                </footer>
            </div>
        </section>
    )
}

export default TaskExercise
