import React from 'react'
import classNames from 'classnames'

import classes from '../styles/exercise.module.sass'
const ExerciseText = ({ title, children, kernelHandler }) => {
    const rootClassNames = classNames(classes.exerciseText, {
        [classes.expanded]: true,
        // Makes component look like completed exercise, but without gray background
        [classes.completed]: false,

    })

    const titleClassNames = classNames(classes.title, {
        [classes.titleExpanded]: true,
    });

    const childrenCopy = React.Children.map(children, child =>
        React.cloneElement(child, {
            excerciseData: [],
            kernelHandler: kernelHandler
        }));

    return (
        <section className={rootClassNames}>
            <h2 className={titleClassNames}>
                <h3>
                    {title}
                </h3>
            </h2>
            <div>
                {childrenCopy}
            </div>
        </section>
    )
}

export default ExerciseText
