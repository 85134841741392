import MathJax from 'react-mathjax2'
import React from 'react'


const Math = (props) => {
    return (
        <MathJax.Context input='tex'>
        <div style={ {"paddingBottom": "1rem"}}>
            <MathJax.Node>{ props.children }</MathJax.Node>
        </div>
        </MathJax.Context>
    );
};

// Requires $...$
export const MathArea = (props) => {
    return (
        <MathJax.Context input='tex'>
        <div>
            <MathJax.Text text={ props.children }></MathJax.Text>
        </div>
        </MathJax.Context>
    );
};

export default Math
