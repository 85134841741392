
import React, { useState, useEffect } from 'react'

const MultiCodeblock = ({children,forceComplete,forceUncomplete, excerciseData, toggleButtonComplete, updateData, submit, kernelHandler: kernelHandler, completed: completed, didMount: didMount}) => {

    // Convert to boolean
    submit = !(submit === "false")

    const [codeBlockData, setCodeBlockData] = useState(Object())
    
    const loadCodeBlockData = (id) => {
        //console.log("loadcodeBlockData() - multi-codeblock")
        // console.log(id)
        // console.log(codeBlockData)
        // console.log(codeBlockData[id] ? codeBlockData[id].data : undefined)
        return codeBlockData[id] ? codeBlockData[id].data : undefined
    }

    const updateStatus = (id, status) => {
        
        if (submit && (codeBlockData[id] !== undefined)) {
           
            codeBlockData[id]["status"] = status
            setCodeBlockData(codeBlockData)

            // **** updatedata ovan kanske inte behövs. Vi testar såhär, blir det problem får vi kolla på detta mer.
            if (allCodeblocksCompleted()) {
                forceComplete()
                updateData(codeBlockData, true)
            }
            else {
                forceUncomplete()
                updateData(codeBlockData, false)
            }
            //console.log("m-c updateData")
            //console.log("codeblockData[id]", codeBlockData[id])
            //updateData(codeBlockData)

        }
    }


    const saveCodeBlockData = (id, data) => {
        
        if (submit){
            codeBlockData[id] = {data: data, status: "uncomplete"}
            setCodeBlockData(codeBlockData)
            //updateData(codeBlockData)
        }
    }

    const getNumCodeblocks = (children) => {
        let numCodeblocks = 0
        children.forEach(element => {
            if (element.props.id !== undefined)
                ++numCodeblocks
        })
        return numCodeblocks
    }

    const getCodeblockIds = (children) => {
        let numCodeblocks = 0
        let codeBlockIds = []
        children.forEach(element => {
            if (element.props.id !== undefined)
                codeBlockIds.push(element.props.id)
        })
        return codeBlockIds
    }

    const allCodeblocksCompleted = () => {
        let codeBlockIds = getCodeblockIds(children)
        const submitAll = codeBlockIds.length <= Object.keys(codeBlockData).length


        if(!submitAll)
            return false
          // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(codeBlockData)) {
            if (codeBlockIds.includes(key)) {
                if (value.status === "uncomplete") {
                    return false
                }
            }
        }
        return true
    }

    useEffect(() => {

        if (excerciseData !== undefined) {
            if (excerciseData.length === 0) {
                setCodeBlockData(Object())
                return
            }

            setCodeBlockData(excerciseData)
        }
        
    }, [excerciseData])

    useEffect(() => {
        if (didMount) {
            if (submit){
                //updateData(codeBlockData)
            }
        }
    }, [toggleButtonComplete])

    const childrenCopy = React.Children.map(children, child =>
        React.cloneElement(child, {loadCodeBlockData: loadCodeBlockData, saveCodeBlockData: saveCodeBlockData, kernelHandler: kernelHandler, updateStatus: updateStatus, submit: submit, updateData: updateData, codeBlockData: codeBlockData, completed: completed}));

    return (
        <>
        {childrenCopy}
        </>
    )
}

MultiCodeblock.defaultProps = {
            submit: true
        };

export default MultiCodeblock

