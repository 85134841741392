import React, { useState } from 'react'
import { Button } from './button'
import { MultianswerContext } from "../context/index";

import { useMsal } from '../utility/auth/msalContext';
import { loginRequest } from "./../utility/auth/msalConfig";


/*
    A component that handles multiple answer, 
    aggregate them into one context(MultianswerContext) and makes the 
    submission controllable for all fields

*/
const MultiAnswer = ({children}) => {
    const [answer, setAnswer]       = useState({})
    const [validate, setValidate]   = useState(false);
    const { loading, isAuthenticated, login, logout, token } = useMsal();


    const updateAnswer = (id, inputText, isCorrect) => {
        let _answer = {...answer};
        _answer[id] = isCorrect // inputText
        setAnswer(_answer);
        setValidate(false);
    }

    const handleAnswer = () => {
        setValidate(true);
    }

    return (
        <>   
            <MultianswerContext.Provider value={{validate, updateAnswer}}>       
                {children}     

            </MultianswerContext.Provider>

            {(!isAuthenticated || !token) && (
                <Button variant="primary" onClick={() => login(loginRequest, "loginRedirect")}>Logga in för att svara</Button>
            )}

            {isAuthenticated && token && (
                <Button variant="primary" onClick={handleAnswer}>
                    Registrera svar
                </Button>
            )}
        </>
    )
}


export default MultiAnswer
